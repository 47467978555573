<template>
  <div class="CaseMainFiles">
     <!-- <div id="browseButton">browseButton</div>
    <div @click='submit'>上传</div> -->
    <div class="top">
      <div class="serchBox">
        <el-input
          size='small'
          class='fileSerchInput'
          placeholder="搜索文件"
          suffix-icon="iconfont iconfangdajing"
          v-model="fileSerchInput"
          clearable>
        </el-input>
      </div>
      <div class="addBox">
        <div class="downLoad">
          <div class="iconfont iconxiazai"></div>
          <div class="text">下载归档文件</div>
        </div>
        <div class="guidang">归档</div>
        <div class="addBtn">
          <myPopover>
            <template v-slot:toggle>
              <div class="add"><div class="iconfont iconicon-test"></div></div>
            </template>
            <div class="list">
              <div>新建目录</div>
              <div>上传文件</div>
              <div @click='createTemplate'>模板目录</div>
            </div>
          </myPopover>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="breadCrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for='(item,index) in fileRoute' :key='index'>{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="tableForm">
        <el-table
            :data="tableData"
            style="width: 100%"
            :default-sort = "{prop: 'date', order: 'descending'}"
            >
            <el-table-column
              label="名称"
              width='500'
             >
              <template #default="scope">
                <div class='fileNameList'>
                  <svg class="icon fileIcon" aria-hidden="true">
                    <use :xlink:href="'#'+iconFontFilter(scope.row.type)"></use>
                  </svg>
                  <span class='fileNameTitle' @click='enterFile(scope.row)'>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="update"
              label="更新时间"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="fileSize"
              label="文件大小"
              sortable
            >
            </el-table-column>
            <el-table-column width='100' align='center'>
              <el-dropdown trigger="click" @command='commandMenu'>
                <div class="iconfont icongengduo"></div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command='1'>重命名</el-dropdown-item>
                    <el-dropdown-item command='2'>下载</el-dropdown-item>
                    <el-dropdown-item command='3'>复制</el-dropdown-item>
                    <el-dropdown-item command='4'>移动</el-dropdown-item>
                    <el-dropdown-item command='5'>删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-table-column>
          </el-table>
      </div>
    </div>
    <myDialog 
    :isShow='isShow'
    :closeDialog='closeDialog'>
      <div class="treeBox">
        <el-tree
        ref="filesTree"
        class='filesTree'
        :data="treeData"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="defaultCheckedKeys"
        :props="defaultProps">
          <template #default="{ node, data }">
            <div class='treeItem'>
              <span class="iconfont">
                <svg class="icon fileIcon" aria-hidden="true">
                  <use :xlink:href="'#'+iconFontFilter(data.type)"></use>
                </svg>
              </span>
              <span class='text'>{{ node.label }}</span>
            </div>
          </template>
      </el-tree>
      </div>
      <div class="filesTreefooterBtn">
        <el-button @click='closeDialog' type="primary" plain style='width:70px' size='small'>取 消</el-button>
        <el-button @click='getFileTree' type="primary" style='width:70px' size='small'>确 定</el-button>
      </div>
    </myDialog>
  </div>
</template>

<script>
// import Uploader from 'simple-uploader.js';
import {defineComponent} from 'vue';
import myDialog from '@/components/myDialog.vue';
import myPopover from '@/components/myPopover.vue';
export default defineComponent({
  name:"ProjectMainFiles",
  components:{
    myPopover,
    myDialog
  },
  data(){
    return {
      fileSerchInput:'',
      tableData: [{
        name: '证据材料',
        update: '2020-01-12 12:22',
        fileSize: '12MB',
        type:"file"
      },{
        name: '传票',
        update: '2020-01-12 12:22',
        fileSize: '12KB',
        type:'img'
      },{
        name: '发票清单',
        update: '2020-01-12 12:22',
        fileSize: '12MB',
        type:'exec'
      },{
        name: '合同',
        update: '2020-01-12 12:22',
        fileSize: '12MB',
        type:"pdf"
      },{
        name: '演讲稿',
        update: '2020-01-12 12:22',
        fileSize: '12MB',
        type:"word"
      }],
      fileRoute:[{
        name:'全部'
      }],
      isShow:false,
      treeData: [{
        id: 1,
        label: '收案审批表',
        type:'file'
      }, {
        id: 2,
        label: '收费凭证',
        type:'file'
      }, {
        id: 3,
        label: '委托材料',
        type:'file',
        children: [{
          id: 8,
          label: '委托书',
          type:'file',
          children: [{
            id: 12,
            label: '委托代理协议',
            type:'file'
          }, {
            id: 13,
            label: '授权委托书',
            type:'file'
          }]
        }, {
          id: 9,
          label: '风险告知书',
          type:'file'
        }]
      }, {
        id: 4,
        label: '阅卷笔录',
        type:'file'
      },{
        id: 5,
        label: '当事人谈话笔录',
        type:'file'
      },{
        id: 6,
        label: '调查资料',
        type:'file',
        children: [{
            id: 10,
            label: '证人证言',
            type:'file'
          }, {
            id: 11,
            label: '书证',
            type:'file'
          }]
      },{
        id: 7,
        label: '法院裁定书',
        type:'file'
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
    }
  },
  
  computed:{
    defaultCheckedKeys(){
      let arr = [];
      for(let i=1;i<=13;i++){
        arr.push(i)
      }
      return arr
    }
  },
  methods: {
    commandMenu(val){
      console.log(val)
    },
    iconFontFilter(type){
      switch(type){
        case 'file':
        return 'iconFOLDER';
        case 'img':
        return 'iconPICTURE';
        case 'exec':
        return 'iconEXCEL';
        case 'pdf':
        return 'iconPDF';
        case 'word':
        return 'iconWORD';
        case 'ppt':
        return 'iconPPT';
        case 'text':
        return 'iconTEXT';
        case 'rar':
        return 'iconRAR';
        case 'audio':
        return 'iconAUDIO';
        case 'video':
        return 'iconVIDEO';
        default:
        return 'iconUNKNOW'
      }
    },
    enterFile(val){
      if(val.type == 'file'){
        // 请求对应文件夹下的文件数据
        this.fileRoute.push({
          name:val.name
        })
      }else{
        // 预览该类型文件
        return
      }
    },
    createTemplate(){
      this.isShow = true
    },
    closeDialog(){
      this.isShow = false
    },
    getFileTree(){
      console.log(this.$refs.filesTree.getCheckedKeys());
      this.closeDialog()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainFiles{
  padding:20px 0;
  .top{
    padding:0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .serchBox{
      .fileSerchInput{
        ::v-deep{
          .el-input__inner{
            border-radius:30px
          }
          .el-input__suffix{
            right:10px
          }
        }
      }
    }
    .addBox{
      display: flex;
      align-items: center;
      .downLoad{
        display: flex;
        align-items: center;
        color:#555;
        &:hover{
          cursor: pointer;
          color:$main-color
        }
        .iconfont{
          font-size: 22px;
        }
        .text{
          font-size: 14px;
          margin-left:5px;
        }
      }
      .guidang{
        width: 90px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 30px;
        border: 1px solid #ddd;
        font-size: 14px;
        color:#555;
        margin:0 15px;
        &:hover{
          cursor: pointer;
          color:$main-color;
          border-color:$main-color;
        }
      }
      .addBtn{
        .add{
          width: 90px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 30px;
          border: 1px solid $main-color;
          font-size: 18px;
          font-weight: 400;
          color:#fff;
          background:$main-color;
          &:hover{
            cursor: pointer;
          }
        }
        .list{
          width: 78px;
          box-sizing: border-box;
          padding: 10px 0;
          div{
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #555;
            padding: 10px 0;
            &:hover{
              cursor: pointer;
              color:$main-color;
            }
          }
        }
      }
    }
  }
  .table{
    padding:30px;
    .breadCrumb{
      padding-left:10px;
      margin-bottom:20px;
    }
    .tableForm{
      border-top:1px solid #eee;
      .el-dropdown{
        &:hover{
          cursor: pointer;
        }
      }
      .fileNameList{
        display: flex;
        align-items: center;
        .fileIcon{
          width:20px;
          height:20px;
        }
        .fileNameTitle{
          font-size: 14px;
          color:#555;
          margin-left:10px;
          &:hover{
            cursor: pointer;
            color:$main-color
          }
        }
      }
      
    }
  }
}
</style>